.swiper-button-prev{
    left: 8rem !important;
    right: auto !important;
    top: 30.5rem !important;
    /* width: 20px !important;
    height: 40px !important;
    border: 8px solid #FFFFFF !important; */
}
.swiper-button-next{
    right: 9rem !important;
    left: auto !important;
    top: 30.5rem !important;
    
    /* width: 20px !important;
    height: 40px !important;
    border: 8px solid #FFFFFF !important; */
}
.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    left: 0 !important;
    width: 100% !important;
    bottom: 6rem !important;
}
.swiper-pagination-bullet {
    width: 9% !important;
    height: 5px !important;
    display: inline-block;
    background: white ! important;
    border-radius: 10px !important;
    opacity: 1 !important;
}
.swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background:#FF5B02 !important;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 1px !important;
}
.swiper-button-prev:after, .swiper-button-next:after {
    color: white ! important;
    font-weight: 900!important;
    font-size: 34px !important;
}
/* fire-box */
.swiper{
    overflow: hidden !important;
}
.swiper-wrapper{
    display: flex !important;
}
/* .swiper-slide {
        display: table !important;
} */
/*-----------------------------------------------------------Semi Desktop----------------------------------------------------------------------- */
@media only screen and (min-width: 1025px) and (max-width: 1366px) {
    .swiper-button-prev {
        left: 4rem !important;
        top: 26.5rem !important;
    }
    .swiper-button-next {
        right: 4rem !important;
        top: 26.5rem !important;
    }
}
/*---------------------------------------------------------------Tab---------------------------------------------------------------------------- */

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .swiper-button-prev {
        left: 4rem !important;
        right: auto !important;
        top: 26rem !important;
    }
    .swiper-button-next {
        right: 5rem !important;
        left: auto !important;
        top: 26rem !important;
    }
    .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
        left: 0 !important;
        width: 100% !important;
        bottom: 4rem !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .swiper-button-prev {
        left: 3rem !important;
        right: auto !important;
        top: 24.5rem !important;
    }
    .swiper-button-next {
        right: 3rem !important;
        left: auto !important;
        top: 24.5rem !important;
    }
    .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
        left: 0 !important;
        width: 100% !important;
        bottom: 5rem !important;
    }
}
/* -------------------------------------------------------------Mobile-------------------------------------------------------------------------- */
 
@media only screen and (max-width: 767px) {
    .swiper-button-prev {
        left: 2rem !important;
        right: auto !important;
        top: 15rem !important;
    }
    .swiper-button-next {
        right: 2rem !important;
        left: auto !important;
        top: 15rem !important;
    }
    .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 1rem !important;
    }
    .swiper-pagination-bullet {
        width: 9% !important;
        height: 3px !important;
    }
    .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0px 2px !important;
    }
}