@keyframes rotateAndShrink {
    0% {
        transform: rotate(0deg) scale(1);
    }
  90%{
    clip-path: none;
  }
    100% {
        transform: rotate(90deg) scale(0.23);
         transform-origin: 70% 73%;
         /* clip-path: polygon(100% 100%,00% 100%,00% 100%, 00% 100%); */
         clip-path: polygon(0% 0%, 100% 0%, 100% 55%, 0% 55%);
    }
}

.animated_image {
    animation: rotateAndShrink 1s ease-in-out forwards;
    transform-origin: center center;
}

.container{
    cursor: pointer;
}
