.niurixdata_wrapper{
    display: flex;
    height: 100%;
    /* width: 100%; */
    /* width: 97%; */

    justify-content: space-around;
    align-items: center;
   
}
.datacount_innerwrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.datacount_count_alignment{
    text-align: center;
    font-size: 80px;
    height: 120px;
    font-family: var(--niurix-number-font);
    /* color: var(--nuirix-font-color); */
    color: #FF5B02;

}
.datacount_desc_alignment{
    font-size: var(--content);
    font-family: 'nunito-regular';
    text-align: center;
    /* font-size: var(--content); */
    width: 85%;
    /* margin-top: 1.5rem; */
    /* font-family: var(--montserrat-regular); */
}

/** mobile **/
@media only screen and (max-width: 767px) {
    .datacount_innerwrapper{
        padding: 0.2rem;
    width: 100%;
    /* width: 25%; */
    }
    .niurixdata_wrapper{
        flex-direction: column;
    }
    .datacount_count_alignment{
        /* font-size: 1.5rem; */
        font-size: 2rem;
        height: 39px;
    }
    .datacount_desc_alignment{
/* font-size: 0.75rem; */
font-size: var(--content-mobile);

min-height: 47px;

width: 100%;
    }
}
@media only screen and (min-width:768px) and (max-width: 1024px) and (orientation: portrait){
    .niurixdata_wrapper{
        flex-direction: column;
        gap: 4rem;
    }
    .datacount_innerwrapper{
        padding: 0.2rem;
    width: 100%;
    /* width: 25%; */
    align-items: unset ;
    }
    .datacount_desc_alignment{
       width: unset;
    }
}
@media only screen and (min-width:768px) and (max-width:1024px) and (orientation: landscape){
    .datacount_count_alignment{
    
     height: 72px;
    }
    .datacount_count_alignment{
        text-align: center;
        font-size: 70px;
        height: 120px;
    
    }
    .datacount_desc_alignment{
        font-size: var(--content-tab);
      
    }

    .niurixdata_wrapper {
        gap: 1rem;
    }
}