.parallax_img_wrapper{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.parallax_img_wrapper{
border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  /* height: 42vh; */
  opacity: 0.9;
  height: 55vh;

  background-image: url("/src/assets/homepage/Contact.webp");
  background-attachment: fixed;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
-webkit-background-size: cover;
position: relative;
}
.parallax_img_padding{
padding: 45px;
}
.parallax_desc_contactus_wrapper{
/* width: 32rem; */
width: calc(100% - 66rem);
  position: absolute;
  right: 5rem;
  top: 8rem;
}
.parallax_contact_us{
padding: 10px;
  background-color: #FF5B02;
  color: white;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* width: 6rem; */
  width: 7rem;
  font-family: 'nunito-medium';

  position: absolute;
  top: 6rem;
  /* right: 48rem;
  bottom: 6rem; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.parallax_text_content{
color: white;
font-family: 'nunito-regular';
font-size: var(--content-header1);
  font-size: 2rem;
  
}
.rightarrow_parallax{
width: 0.8rem;
height: 0.8rem;
margin-left: 0.5rem;
}
/** mobile **/
@media only screen and (max-width: 767px) {
.parallax_img_padding{
  padding: 20px;
}
.parallax_text_content{
  width: 100%;
  /* width: 100%; */
  font-size: var(--content-header1-mobile);
}

.parallax_desc_contactus_wrapper{
  left: 50%;
  transform: translate(-50%);
  width: 70%;
  top: 39%;
}
.parallax_contact_us{
width: 6rem;
top: 4rem;
left: 0rem;
font-size: var(--footer-menuname-mobile);
}
.rightarrow_parallax{
width: 0.6rem;
height: 0.6rem;
}


}
/* tab  */
@media only screen and (min-width:768px) and (max-width:1024px) and (orientation:portrait){

.parallax_desc_contactus_wrapper{
    right: 5rem;
    top: 16rem;
}
.parallax_contact_us{
     top: 7rem;
     left: 1rem;
}
.parallax_desc_contactus_wrapper{
  /* width: 32rem; */
  width: calc(100% - 15rem);
}
.parallax_img_padding{
  padding: 0 80px 20px;
}

}
@media only screen and (min-width: 768px) and (max-width:1024px) and (orientation:landscape){
.parallax_img_padding{
  padding:  78px;
}
.parallax_desc_contactus_wrapper{
  /* width: 32rem; */
  width: calc(100% - 24rem);
    position: absolute;
    right: 12rem;
    top: 9rem !important;
}
.parallax_contact_us{
  top: 6rem !important;
}
}
/* semidesktop */
@media only screen and (min-width:1025px) and (max-width: 1366px){
.parallax_img_padding{
     padding:  120px;
}
.parallax_desc_contactus_wrapper{
   
    width: 60%;
    left: 30%;
    transform: translate(-50px);
    top: 45%;
}

.parallax_contact_us{
  top: 4rem;
  left: 1%;
}


.parallax_img_wrapper{
  background-attachment: unset;

}

}
/* mid size laptop */

@media only screen and (min-width:1367px) and (max-width:1600px) {
  .parallax_desc_contactus_wrapper{
    /* width: 32rem; */
    width: calc(100% - 35rem);
    position: absolute;
    right: 13rem;
    top: 24rem;
  }
}