.footer{
    width: 100%; 
    position: relative;
 }
 .footer_container{
     padding: 0 120px;
 }
 .footer_wrapper{
     display: flex;
     padding: 2rem 1rem 5rem;
     border-top: solid #DFDFDF 2px;
     justify-content: space-between;
 }
 .footer_list{
     display: flex;
     justify-content: flex-end;
     width: 88%;
     padding: 0.5rem 0 0 0;
 }
 .footer_list_items1{
     width: 27%;
 }
 .footer_list_items2{
     width: 14%;
 }.footer_list_items3{
     width: 27%;
 }.footer_list_items4{
     width: 15%;
 }
 .footer_underline{
     padding: 1.5rem 1rem 1.5rem 0;
     border-top:1px solid #707070 ;
     width: 99%;
     display: flex;
 }
 .footer_arr{
     width: 45%;
 }
 .footer_underline_container{
     display: flex;
     width: 55%;
     justify-content: space-between;
 }
 .finduson{
     display: flex;
     justify-content: space-between;
     width: 19%;
 }
 .terms_privacy{
     display: flex;
     justify-content: space-between;
     width: 41%;
 }
 .footer_list_heading{
     margin-bottom: 1rem;
     color: #000000;
     font-size: var(--content);
     font-family: var(--subheading-font);
    
 }
 .footer_blog{
     font-size: var(--content);
     font-family: var(--subheading-font);
 }
 .list_sub_item{
     font-size: var(--footer-menu-name);
     font-family: var(--subheading-font);
     line-height: 2rem;
     cursor: pointer;
 
 }
 .footer_2023{
     font-size: var(--footer-menu-name);
     font-family: var(--subheading-font);
 }
 .terms{
     font-size: var(--footer-menu-name);
     font-family: var(--subheading-font);
 }
 .privacy{
     font-size: var(--footer-menu-name);
     font-family: var(--subheading-font);
 }
 .findus{
     font-size: var(--footer-menu-name);
     font-family: var(--subheading-font);
 }
 .footer_list_items3 .footer_list_heading{
     cursor: pointer;
 }
 .footer_logo{
     cursor: pointer;
 }
 .scrolltotop{
position: fixed;
height: 4rem;
width: 4rem;
bottom: 8%;
cursor: pointer;
right: 1.7%;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 8px 15px #00000029;
opacity: 1;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50px;
z-index: 10;
}
.footer_mobile{
    display: none;
}
@media only screen and (max-width: 767px) {
.footer_container{
    padding: 0 36px;
}
.footer_wrapper{
    flex-direction: column;
}
.footer_list{
    width: 100%;
    flex-direction: column;
}
.footer_list_items1{
    width: 100%;
    margin-bottom: 5%;
}
.footer_list_items2{
    width: 100%;
    margin-bottom: 5%;

}.footer_list_items3{
    width: 100%;
    margin-bottom: 5%;

}.footer_list_items4{
    width: 100%;
}
.footer_logo{
    margin-bottom: 5%;
}
.footer_list_heading{
    margin-bottom: 2%;
    font-size: var(--mobile-font-h5);
    font-weight: 600;
}
.list_sub_item{
    font-size: var(--mobile-font-h6);
    /* line-height: 1.5rem; */
}
.footer_underline{
    padding: unset;
    width: 100%;
    flex-direction: column;
}
.footer_arr{
    width: 100%;
    text-align: center;
    margin-bottom: 5%;
    margin-top: 5%;
}
.footer_underline_container{
    flex-direction: column;
    width: 100%;
}
.terms_privacy{
    width: 100%;
    margin-bottom: 5%;
}
.terms{
    width: 50%;
    text-align: center;
}
.privacy{
    width: 50%;
    text-align: center;
}
.finduson{
    width: 100%;
    justify-content: unset;
    flex-direction: column;
    align-items: center;
}
.linkedin_logo{
    display: none;
}
.twitter_logo{
    display:none;
}
.footer_mobile{

    display: flex;
    gap: 2%;
    width: 100%;
    justify-content: center;
}
.findus{
    margin-bottom: 5%;
}
.footer_2023{
    font-size: var(--mobile-font-h6);
}
.terms{
    font-size: var(--mobile-font-h6);

}
.privacy{
    font-size: var(--mobile-font-h6);

}
.findus{
    font-size: var(--mobile-font-h6);

}

}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .footer_container{
        padding: 0 80px;
    }
    .footer_wrapper{
        flex-direction: column;
    }
    .footer_list{
        width: 100%;
        flex-direction: column;
    }
    .footer_list_items1{
        width: 100%;
        margin-bottom: 5%;
    }
    .footer_list_items2{
        width: 100%;
        margin-bottom: 5%;
    
    }.footer_list_items3{
        width: 100%;
        margin-bottom: 5%;
    
    }.footer_list_items4{
        width: 100%;
    }
    .footer_logo{
        margin-bottom: 5%;
    }
    .footer_list_heading{
        margin-bottom: 2%;
        font-weight: 600;
        font-size: var(--tab-font-h5);
   
    }
    .list_sub_item{
        font-size: var(--tab-font-h6);
        /* line-height: 1.5rem; */
    }
    .footer_2023{
        font-size: var(--tab-font-h6);
    }
    .terms{
        font-size: var(--tab-font-h6);
    
    }
    .privacy{
        font-size: var(--tab-font-h6);
    
    }
    .findus{
        font-size: var(--tab-font-h6);
    
    }
    .footer_underline{
        padding: unset;
        width: 100%;
        flex-direction: column;
    }
    .footer_arr{
        width: 100%;
        text-align: center;
        margin-bottom: 5%;
        margin-top: 5%;
    }
    .footer_underline_container{
        flex-direction: column;
        width: 100%;
    }
    .terms_privacy{
        width: 100%;
        margin-bottom: 5%;
    }
    .terms{
        width: 50%;
        text-align: center;
    }
    .privacy{
        width: 50%;
        text-align: center;
    }
    .finduson{
        width: 100%;
        justify-content: unset;
        flex-direction: column;
        align-items: center;
    }
    .linkedin_logo{
        display: none;
    }
    .twitter_logo{
        display:none;
    }
    .footer_mobile{
    
        display: flex;
        gap: 2%;
        width: 100%;
        justify-content: center;
    }
    .findus{
        margin-bottom: 5%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
.finduson{
    width: 33%;
}
.terms_privacy{
    width: 61%;
}
.footer_container{
    padding: 0 80px;
}
.footer_list{
    justify-content: unset;
    width: 82%;
    gap: 2rem;
}
}

/*Semi Desktop */
@media only screen and (min-width: 1025px) and (max-width: 1366px) {
    .footer_list{
        width: 100%;
        gap: 1.5rem;
    }
    .footer_list_items3{
        width: 26%;
    }
}

/* Mid-size Laptop */

@media only screen and (min-width: 1367px) and (max-width: 1600px) {
    .footer_list{
        width: 100%;
        gap: 1.5rem;
    }
    .footer_list_items3{
        width: 26%;
    }
    .finduson{
        width: 22%;
    }
}