@keyframes rotateAndShrink {
    /* 0% {
        transform: rotate(0deg) scale(1);
    }
   */
   90%{
    clip-path: none;
  }
    100% {
        transform:scale(0.3);
         transform-origin: 56.5% 73.6%;
         clip-path: polygon(0% 30%, 100% 30%, 100% 100%, 0% 100%);
    }
}

.animated_image {
    animation: rotateAndShrink 1s ease-in-out  forwards;
    transform-origin: center center;
}

.container{
    cursor: pointer;
}
