.react-tel-input {
    width: 100%;
    height: 47px;
}
.react-tel-input .form-control {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #1d1d1d;
    border-radius: 10px 0px;
    opacity: 0.3;
    width: 100%;
    height: 100%;
}
.react-tel-input .flag-dropdown {
    border-radius: 10px 0 0 0;
}

.form-control::placeholder {
    font-family: var(--content-font);
    font-size: var(--content);
}
