.hwcard_wrapper{
   
   
    position: absolute;
   
    right: 65px;
   
   
    /* width: 33%; */
    width: 30%;

    
    bottom: 19rem;

z-index: 3;
    
    position: absolute;
    /* z-index: -10000; */
}
.box1{

    position: absolute;  
    left: 37%; 
    transition: 2.2s ease-in-out;
z-index: 10;
top: -4rem;

}
.box2{

    left: 22%;
    position: absolute;   
    transition: 2.4s ease-in-out;
    z-index: 9;


}
.box3{

    position: absolute;  
    left: 14%; 
    transition: 2.6s ease-in-out;
    z-index: 8;

}
.box4{

    position: absolute; 
    left:7%; 
    transition: 2.8s ease-in-out;
    z-index: 7;



}


.box5{
    
    position: absolute;  
    left: 2%; 
    transition: 3s ease-in-out;
    z-index: 6;


}
.boxrotated{
    height: 37rem;
    width: 100%;
    position: absolute;   

}
.box{
    width: 100%;
    height: 100%;
}
.secondscroll_box1{

    position: absolute;   
    left: 35%;
    z-index: 10;
top: -4rem;
    transition: 3s ease-in-out;

}
.secondscroll_box2{

    position: absolute;   
    left: 26%;
    z-index: 9;

    transition: 3s ease-in-out;


}
.secondscroll_box3{

    position: absolute;  
    left: 22%; 
    transition: 3s ease-in-out;
    z-index: 8;


}
.secondscroll_box4{

    position: absolute; 
    left: 16%; 
    transition: 3s ease-in-out;
    z-index: 7;


  

}
.secondscroll_box5{

    position: absolute;  
    left:11%; 
    z-index: 6;

    transition: 3s ease-in-out;
    
}
.thirdscroll_box1{
position: absolute;
transition: 3s ease-in-out;
top: -4rem;
z-index: 10;
left: 33%;


}
.thirdscroll_box2{
    position: absolute;
    transition: 3s ease-in-out;
    z-index: 9;
    left: 28%;


}
.thirdscroll_box3{
    position: absolute;
    transition: 3s ease-in-out;
    z-index: 8;
    left: 24%;



}
.thirdscroll_box4{
    position: absolute;
    transition: 3s ease-in-out;
    z-index: 7;
    left: 18%;


}
.thirdscroll_box5{
    position: absolute;
    transition: 3s ease-in-out;
    z-index: 6;
    left: 13%;



}
.fourthscroll_box1{
    position: absolute;
    transition: 3s ease-in-out;
    top: -4rem;

    z-index: 10;
    left: 31%;
}
.fourthscroll_box2{
    position: absolute;
    transition: 3s ease-in-out;
    z-index: 9;
    left: 29%;
}.fourthscroll_box3{
    position: absolute;
    transition: 3s ease-in-out;
    z-index: 8;
    left: 26%;



}.fourthscroll_box4{
    position: absolute;
    transition: 3s ease-in-out;
    z-index: 7;
    left: 20%;


}.fourthscroll_box5{
    position: absolute;
    transition: 3s ease-in-out;
    z-index: 6;
    left: 16%;


}
.finalScroll1{
    position: absolute;
    left: 21%;
    animation: Animation 3s forwards;
    position: absolute;
    transition: 3s ease-in-out;
    top: -4rem;
    z-index: 10;


}
.finalScroll2{
    position: absolute;
    left: 23%;
    z-index: 9;

    transition: 3s ease-in-out;

}.finalScroll3{
    position: absolute;
    left: 23%;
    z-index: 8;

    transition: 3s ease-in-out;

}.finalScroll4{
    position: absolute;
    left: 23%;
    transition:  3s ease-in-out;
    z-index: 7;


}





@keyframes Animation {
    90% {
        transform: rotate(-15deg);
    }
    95% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
.finalScroll5{
    position: absolute;
    left: 23%;
    transition: 3s ease-in-out;
    z-index: 6;


}
.hwcard_header{
    /* color: var(--nuirix-font-color); */
    /* color: var(--nuirix-font-color); */
    color: #FF5B02;
}
.hwcard_content
{
    /* width: 60%;
    margin-left: 2rem; */
    width: 90%;
    /* margin-left: 2rem; */
    font-size: var(--content-header2);
}
.card_hardware_wrapper{
    /* position: absolute;
    right: 10%;
    top: -70%;
    width: 40%; */
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(249px,1fr));
    /* grid-gap: 0rem; */
    /* width: 80%; */
}
.hwcard_heading{
    /* margin-left: 2rem; */
    font-size: var(--content-font);
    font-weight: unset;
    font-family: 'nunito-regular';
}
.hwcard_single_card{
   border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 97%;
    display: flex;
    flex-direction: column;
    border-bottom-right-radius: 16px;
    border-top-left-radius: 16px;
    /* height: 8.5rem; */
    height: 9.5rem;
    /* width: 60%; */

   

    z-index: 20;
    box-shadow:0px 3px 15px #0000001F;
    background-color: white;
    /* margin-left: 8%; */
    margin-top: 8%;
}
.hwcard_single_card:hover{
    border: 1px solid var(--nuirix-font-color);
}
.card_hardware_text{
    /* width: 42%; */
    /* width: 65%; */
    width: 60%;
    font-size: var(--content);


    text-align: center;
    font-family: 'nunito-regular';
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;

}
/* animation */
.video_wrapper_hardware{
    /* width: 100% ;
    height: 50% ; */
}
/* .scroll_animation_wrapper{
    position: absolute;  
width: 100%; left:-30% ;
} */
.video_wrapper_hardware{

    /* height: 100vh; */
    /* height: 100%; */
    /* height: 97%; */
    /* display: flex; */
position: absolute;  
width: 100%; 
top: 24%;
/* top: 50%; */
}

/** mobile **/
@media only screen and (max-width: 600px) {
    .video_wrapper_hardware{
        /* height: 30%; */
        /* height: 4.5rem; */
    }
    .hwcard_single_card{
        /* width: 96%; */
        /* width: 80%; */
        height: 4.5rem;

    }
    .video_wrapper_hardware{
        background-image: url("/src/assets/homepage/mobbackground.png");
        width: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 50%;
        height: 100%;
        position: unset;
    }

    .boxes{
        display: none;
    }
    .card_hardware_text{
        font-size: 0.65rem;
        width: 64%;
    }
    .hwcard_content{
        width: 100%;
        font-size: 0.75rem;
    }
    .hwcard_wrapper{
        /* right: 12px; */
        /* bottom: 25rem; */
     
        /* position: unset; */
        
            /* right: 12px; */
            /* bottom: 25rem; */
            /* left: 19px; */
            /* position: unset; */
            bottom: 1rem;
            padding: 0.3rem;
            width: 65%;
            top: 24rem;
        
    }
    .card_hardware_wrapper{
        grid-template-columns: repeat(auto-fill,minmax(290px,1fr));
    }
    .hwcard_single_card{ 
         width: 90%; 
     }
     .hwcard_heading {
        position: relative;
        top: -21rem;
        font-size: var(--content-header1-mobile);
    }
}

/** semi **/
@media only screen and (min-width: 1025px) and (max-width: 1366px) {
    .card_hardware_wrapper{
        
            grid-template-columns: repeat(auto-fill,minmax(136px,1fr));
          grid-gap: 2rem;
    }
    .hwcard_single_card{
        width: 100%;
        padding: 0.5rem;
    }
    .hwcard_wrapper{
         bottom: 18rem;
    }
    .card_hardware_text{
        /* width: 42%; */
        /* width: 65%; */
        width: 100%;
    
    }
    .box5{
        left: 1%;
    }
    .box4{
        left: 9%;
    }
    .box3{
        left: 17%;
    }
    .box2{
        left: 25%;
    }
    .box1{
        left: 40%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation:portrait){
    .video_wrapper_hardware{
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 50%;
        height: 100%;
        width: 100%;
        position: unset;
    }
    .hwcard_single_card{
        /* width: 96%; */
        /* width: 80%; */
        height: 7.5rem;

    }
    .boxes{
        display: none;
    }

    .hwcard_heading{
            font-family: 'nunito-regular';
            font-size: var(--sub-heading-tab);
            width: 97%;
            text-align: center;
    }
    .video_wrapper_hardware{
        background-image: url("/src/assets/homepage/mobbackground.png");
    }

    .card_hardware_text{
        font-size: 1.5rem;
        width: 64%;
    }
    .hwcard_content{
        width: 100%;
        font-size: 0.75rem;
    }
    .hwcard_wrapper{
        /* right: 12px; */
        /* bottom: 25rem; */
        left: 50%;
        right: unset;
        transform: translate(-50%);
                /* position: unset; */
        bottom: unset;
        top: 50%;
        /* padding: 0.3rem; */
        width: 100%;
    }
    .card_hardware_wrapper{
       
            grid-template-columns: repeat(auto-fill,minmax(280px,1fr));
            padding: 0 78px;
            /* margin-right: 3rem; */
        }
    
}
@media only screen and (min-width:768px) and (max-width: 1024px) and (orientation: landscape){
    .card_hardware_wrapper{
            display: grid;
            grid-template-columns: repeat(auto-fill,minmax(130px,1fr));
            grid-gap: 2rem;
            /* width: 80%; */
        }
        .card_hardware_text{
          font-size: var(--content-tab);
          width: 90%;
        }
        .video_wrapper_hardware{
            background-image: url("/src/assets/homepage/Box\ section1-tab.webp");
            width: 100%;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            height: 50%;
            height: 100%;
            position: unset;
        }
        .boxes{
            display: none;
        }
    }
/* mid size laptop */
 @media only screen and (min-width:1367px) and (max-width:1600px) {
    .card_hardware_wrapper{
       
        grid-template-columns: repeat(auto-fill,minmax(228px,1fr));
        grid-gap: 1rem;
    
    }
    .box5{
        left: 1%;
    }
    .box4{
        left: 9%;
    }
    .box3{
        left: 17%;
    }
    .box2{
        left: 25%;
    }
    .box1{
        left: 40%;
    }
 }