@keyframes myAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.animated_image {
    animation: myAnimation 1s;
}


.container{
    cursor: pointer;
}