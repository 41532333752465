.software_section_banner {
    width: 100%;
    margin-bottom: 3rem;
    position: relative;
}
.software_section_banner img {
    width: 100%;
    height: 100%;
}
.software_section_banner_content {
    position: absolute;
    top: 30rem;
    padding: 0 120px;
    color: #fff;
    width: 35%;
}
.software_section_banner_content h1 {
    font-size: var(--banner-header);
    font-family: var(--subheading-font);
    font-weight: unset;
}
.software_content_body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
    padding: 0 120px;
}
.software_section_title {
    margin-bottom: 1.5rem;
}
.software_section_title h2 {
    color: var(--nuirix-subheading-font);
    font-size: var(--sub-heading);
    font-family: var(--subheading-font);
    font-weight: unset;
}
.software_section_para {
    color: var(--nuirix-subheading-font);
    font-size: var(--footer-menuname);
    font-family: var(--content-font);
    margin-top: 1rem;
}
.cards_container {
    display: flex;
    justify-content: space-between;
    padding: 120px 120px 0 120px;
}
.cards_wrapper {
    position: relative;
}
.cards_img_wrapper {
    position: relative;

}
.cards_img_wrapper img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.card_desc {
    position: absolute;
    top: 30%;
}
.card_para {
    white-space: wrap;
    width: 90%;
    color: white;
    padding: 0 1rem;
    font-size: var(--content);
    font-family: var(--subheading-font);
    opacity: 0.8;
}

.cards {
    position: relative;
}

.title1 {
    padding: 0 1rem;
    position: absolute;
    top: 80%;
    color: white;
    font-size: var(--content-header1);
    font-family: var(--subheading-font);
    transition: 0.6s cubic-bezier(0.45, -0.35, 0.27, 1.25);
}

.cards_wrapper:hover .title1 {
    top: 10%;

    transition: 0.6s cubic-bezier(0.4, -0.55, 0.27, 1.35);
    color: #ff5b02;
}

.cards_wrapper img {
    filter: brightness(1);
    transition: filter 0.5s ease-in-out;
}

.cards_wrapper:hover img {
    filter: brightness(0.4);
    transition-delay: 0.1s;
}

.our_software {
    font-size: var(--content-header1);
    font-family: var(--content-header1-font);
}

.our_software span {
    color: #ff5b02;
}


@media only screen and (min-width: 1367px) and (max-width: 1600px) {
}

@media only screen and (min-width: 1025px) and (max-width: 1366px) {
    .software_section_banner_content {

        top: 20rem;
  
        width: 50%;
    }
    .cards_img_wrapper{
        height: 40rem;
        width: 13rem;
    }
    .title1 {
        top: 75%;
    }
    .cards_wrapper:hover .title1 {
        top: 5%;
    }
}




@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .cards_container {
        flex-direction: column;
    gap: 2rem;}
    .software_section_banner_content {
        position: absolute;
        top: 12rem;
        padding: 0 80px;
        color: #fff;
        width: 70%;
    }
    .software_content_body {
        padding: 0 80px;
    }
    .cards_container {
        padding: 80px 80px 0 80px;
    }

    .cards_img_wrapper img {
        object-fit: fill;

    }

    .software_section_banner_content h1 {
        font-size: var(--banner-header-tab);
    }

    .software_section_title h2 {
        font-size: var(--sub-heading-tab);
    }
    .software_section_para {
        font-size: var(--footer-menuname-tab);
    }
    .card_para {
        font-size: var(--content-tab);
    }

    .title1 {
        font-size: var(--content-header1-tab);
    }
    .our_software {
        font-size: var(--content-header1-tab);
    }
    .title1 {

        transition: none;
        top: 18%;
        padding: 0 30px;
        color: #ff5b02;
        
    }
    .cards_img_wrapper{
        height: 17rem;
    }
   
 .card_para{
    
    padding: 0 30px;
 }
 .cards_wrapper:hover img {
    filter: none;
    transition-delay: none;
}
.cards_wrapper:hover .title1 {

    top:18%;
    transition: none;
}
.card_desc{
    top: 40%;
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .software_section_banner_content {
        padding: 0 78px;
        top: 13rem;
        width: 60%;
    }
    .software_content_body {
        padding: 0 78px;
    }
    .cards_container {
        padding: 78px 78px 0 78px;
    }
    .cards_img_wrapper{
        width: 10rem;
        height: 35rem;
    }
    .title1 {
        top: 75%;
    }
    .card_desc {
        top: 26%;
    }
    .cards_wrapper:hover .title1 {
        top: 5%;
    }
    .software_section_banner_content h1 {
        font-size: var(--banner-header-tab);
    }

    .software_section_title h2 {
        font-size: var(--sub-heading-tab);
    }
    .software_section_para {
        font-size: var(--footer-menuname-tab);
    }
    .card_para {
        font-size: var(--content-tab);
    }

    .title1 {
        font-size: var(--content-header1-tab);
    }
    .our_software {
        font-size: var(--content-header1-tab);
    }
}




@media only screen and (max-width: 767px) {
    .our_software {
        white-space: break-spaces;
    }
    .cards_container {
        flex-direction: column;
        padding: 0 36px;
        gap: 2rem;
        margin: 2rem 0;
    }


    .title1 {
  
        transition: none;
        top: 10%;
    }
    .software_section_banner_content {
        top: 23rem;
        padding: 0 0 0 36px;
        width: 85%;
    }
    .software_section_banner_content h1 {
        font-size: var(--banner-header-mobile);
    }
    .software_content_body {
        padding: 0 36px;
    }
    .title1 {
        color: #ff5b02;
        font-size: var(--content-header1-mobile);
    }
    .card_para {
        font-size: var(--content-mobile);
        opacity: 1;
    }
    .software_section_title h2 {
        font-size: var(--sub-heading-mobile);
    }
    .software_section_para {
        font-size: var(--footer-menuname-mobile);
    }
    .our_software {
        font-size: var(--content-header1-mobile);
    }
    .card_desc {
        top: 35%;
    }
    .cards_wrapper:hover img {
        filter: none;
        transition-delay: none;
    }

}

