.cards_container {
    display: flex;
    position: relative;
    padding: 0 120px;


    gap: 2rem;
}
.img2_wrapper{
    position: absolute;
    top: 0;
    width: 50em;
    height: 35rem;
}
.img2 {
    height: 100%;
    width: 100%;
    
}

.industries_button {
    padding: 10px;
    background-color: #ff5b02;
    color: white;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 20%;

    font-size: var(--content);
    font-family: var(--subheading-font);
}

.card_content {
    position: absolute;
    bottom: 0rem;
    padding: 20px 0 20px 20px;
    background-color: white;
    opacity: 0.95;
  width: -webkit-fill-available;
    max-height: 30%;
}

.card_title {
    margin-bottom: 1rem;
    font-size: var(--content-header1);
    font-family: var(--subheading-font);
    color: var(--nuirix-subheading-font);
}

.card_desc {
    margin-bottom: 1rem;
    font-size: var(--content);
    font-family: var(--content-font);
    color: var(--nuirix-subheading-font);
}

.image1 {
    position: relative;
    width: 15rem;
    height: 35rem;
    cursor: pointer;
}

.image1 img {
    width: 100%;
    height: 100%;
}

.image_title {
    position: absolute;
    bottom: 0rem;
    text-align: start;
    left: 1rem;
    width: 18rem;
    color: white;
    transform: rotate(-90deg);
    transform-origin: top left;
    font-size: var(--content-header1);
    font-family: var(--content-font);
}




.expanded_image {
    width: 50rem;
}

.industries_heading {

    font-size: var(--content-header1);
    font-weight: unset;
    padding:  0 120px 40px 120px;

    font-family: var(--content-header1-font);
}
.industries_heading span {
    color: #ff5b02;
}

.industries_button {
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}
.industries_button:hover {
    background-color: white;
    border: 2px solid #ff5b02;
    color: black;
}

.industries_button:hover .rightarrow path {
    stroke: black;
}

.cards_container_mob{
    display: none;
}
@media only screen and (min-width: 1025px) and (max-width: 1366px) {
    .image1 {

        width: 13rem;
        height: 30rem;
    }
    .img2_wrapper{
        width: 25em;
        height: 30rem;
    }
    .expanded_image {
        width: 25rem;
    }
    .img2 {
        object-fit: cover;
        border-top-left-radius: 35px;
        border-bottom-right-radius: 35px;
    }
    .card_content {

       
        width: -webkit-fill-available;

        max-height: 40%;
    }
    .industries_button {
        width: 44%;
    }
}
@media only screen and (min-width: 1367px) and (max-width: 1600px) {
    .expanded_image {
        width: 34%;
    }
    .img2_wrapper{

width: 34%;    }
.industries_button{
    width: 48%;
}
.card_content{
    max-height: 46%;
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .cards_container{
        display: none;
    }
    .cards_container_mob{
        padding: 0 80px;
        display: flex;
        flex-direction: column;
        gap: 2rem;

    }

    .image1_mob{
        position: relative;
    }

    .image1_mob img{
        height: 100%;
        width: 100%;
        object-fit: fill;
    }
    .img2_mob{
        position: absolute;
        bottom: 0;
        
    }
    .expanded_image_mob {
        height: 26rem;
    }

    .card_content_mob {
        position: absolute;
        bottom: 0rem;
        padding: 30px 0 30px 30px;
        background-color: white;
        opacity: 0.95;
  
        width: -webkit-fill-available;
        max-height: 45%;
    }
    
    .card_title_mob {
        margin-bottom: 1rem;
        font-size: var(--content-header1-tab);
        font-family: var(--subheading-font);
        color: var(--nuirix-subheading-font);
    }
    
    .card_desc_mob {
        width: 90%;
        margin-bottom: 1rem;
        font-size: var(--content-tab);
        font-family: var(--content-font);
        color: var(--nuirix-subheading-font);
    }


    .industries_heading {
      
        font-size: var(--content-header1-tab);
        font-weight: 200;
        padding: 0 80px 40px 80px;

        font-family: var(--content-header1-font);
    }

    .industries_button_mob {
        padding: 10px;
        background-color: #ff5b02;
        color: white;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        width: 22%;
    
        font-size: var(--content-tab);
        font-family: var(--subheading-font);
    }
    .industries_button_mob {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .image_title_mob {
        position: absolute;
        bottom: 3rem;
        left: 1rem;
 
        color: white;

        font-size: var(--content-header1-tab);
        font-family: var(--content-font);
    }

}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .cards_container {
        padding: 0 78px;
        gap: 1rem;
    }
    .image1 {
        height: 30rem;
        width: 10rem;
    }
    .img2_wrapper{
        width: 22rem;
        height: 30rem;
    }
    .expanded_image {
        width: 22rem;
    }
    .industries_heading {
        padding: 40px 78px;
    }
    .card_content {
        max-height: 45%;
    }
    .industries_button {
        width: 38%;
    }
    .card_title_mob {
        font-size: var(--content-header1-tab);
    }
    .card_desc_mob {
        font-size: var(--content-tab);
    }
    .industries_heading {
        font-size: var(--content-header1-tab);
    }
    .industries_button_mob{
        font-size: var(--content-tab);
    }
    .image_title_mob{
        font-size: var(--content-header1-tab);
    }
}
@media only screen and (max-width: 767px) {
    .cards_container{
        display: none;
    }
    .cards_container_mob{
        padding: 0 36px;
        display: flex;
        flex-direction: column;
        gap: 1rem;

    }

    .image1_mob{
        position: relative;
    }

    .image1_mob img{
        height: 100%;
        width: 100%;
    }
    .img2_mob{
        position: absolute;
        bottom: 0;
        
    }
    .expanded_image_mob {
        height: 26rem;
    }

    .card_content_mob {
        position: absolute;
        bottom: 0rem;
        padding: 20px 0 20px 20px;
        background-color: white;
        opacity: 0.95;

        width: -webkit-fill-available;
        max-height: 45%;
    }
    
    .card_title_mob {
        margin-bottom: 1rem;
        font-size: var(--content-header1-mobile);
        font-family: var(--subheading-font);
        color: var(--nuirix-subheading-font);
    }
    
    .card_desc_mob {
        width: 95%;
        margin-bottom: 1rem;
        font-size: var(--content-mobile);
        font-family: var(--content-font);
        color: var(--nuirix-subheading-font);
    }


    .industries_heading {
      
        font-size: var(--content-header1-mobile);
        font-weight: 200;
        padding:  36px;
       
        font-family: var(--content-header1-font);
    }

    .industries_button_mob {
        padding: 10px;
        background-color: #ff5b02;
        color: white;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        width: 40%;
    
        font-size: var(--content-mobile);
        font-family: var(--subheading-font);
    }
    .industries_button_mob {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .image_title_mob {
        position: absolute;
        bottom: 1.3rem;
        left: 1rem;

        color: white;
    
        font-size: var(--content-header1-mobile);
        font-family: var(--content-font);
    }

}