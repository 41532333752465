.section1_banner_img {
    width: 100%;
    height: 100%;
}

.section1_banner_img img {
    width: 100%;
    height: 100%;
    filter: brightness(0.6);
}

.section1_banner_title {
    position: absolute;
    top: 30rem;
    padding: 0 120px;
    color: white;
    font-family: var(--subheading-font);
    font-size: var(--banner-header);
    width: 50%;
    font-weight: unset;
}

.section2 {
    padding: 0 120px;
    margin: 6rem 0;
}

.section2_wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.section2_heading {
    font-size: var(--content-header1);
    font-family: var(--content-header1-font);
    width: 65%;
    font-weight: unset;
}

.section2_content {
    width: calc(100% - 74%);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.section2_img {
    width: 60%;
    /* height: 10rem; */
}
.section2_img img {
    width: 100%;
    height: 100%;
}
.section2_button {
    border: 2px solid #ff5b02;
    padding: 9px;
    border-top-left-radius: 10px;
    width: 28%;
    border-bottom-right-radius: 10px;
    background-color: #ff5b02;
    font-size: var(--content);
    font-family: var(--subheading-font);
}
.section2_button:hover {
    background-color: white;
}

.section2_button a {
    text-decoration: none;
    font-family: "nunito-regular";
    color: white;
    display: flex;
    justify-content: center;
}

.section2_button:hover a {
    color: black;
}

.section2_desc {
    margin: 3rem 0;
    font-size: var(--content);
    font-family: var(--content-font);
}
.our_industries {
    font-size: var(--content-header1);
    font-family: var(--content-header1-font);
    width: 65%;
    font-weight: unset;
}
.our_industries span {
    color: #ff5b02;
}

.section3 {
    display: flex;
    justify-content: center;
    /* border: 1px solid red; */
    padding: 4rem;
    flex-wrap: wrap;
    gap: 3rem;
    border-radius: 9px;
    box-shadow: 0 0 10px #00000029;
    margin: 20px 120px;
}

.section3_content {
    text-align: center;
}

.section3_wrapper {
    display: flex;
    align-items: center;
    height: 6rem;
}

.section3_title {
    font-family: var(--subheading-font);
}

.section3_img {
    /* width: 10rem; */
    /* height: 3rem; */
    margin: 1rem 1rem;
}
.section3_img img {
    width: 100%;
    height: 100%;
}

.section4 {
    background-color: var(--niurix-homepage-products);
    padding: 4% 120px;
    /* margin: 13rem 0; */
    margin: 15rem 0 6rem 0;
    position: relative;
    height: 13rem;
}

.section4_content {
    width: 40%;
}

.section4_heading {
    margin-bottom: 1rem;
    font-size: var(--content-header1);
    font-family: var(--subheading-font);
    font-weight: unset;
}

.section4_desc {
    font-size: var(--content);
    font-family: var(--content-font);
}

.section4_cards {
    position: absolute;
    right: 10%;
    top: -40%;
    width: 40%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 2rem;
    z-index: 1;
}

.section4_cards_content {
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    border-bottom-right-radius: 16px;
    border-top-left-radius: 16px;
    /* justify-content: center; */
    height: 15rem;
    /* align-items: center; */
    z-index: 20;
    box-shadow: 0 0 10px #00000029;
    background-color: white;

    margin-left: 8%;
    margin-top: 8%;
}

.section4_cards_img {
    width: 5rem;
    /* height: 6rem; */
    margin: 2rem 2rem;
    display: flex;
}
.section4_cards_img img {
    width: 100%;
    height: 100%;
}

.section4_cards_title {
    margin: 0rem 2rem;
    font-family: var(--subheading-font);
    font-size: var(--content);
}

/* Media Query */

/*Semi-desktop*/
@media only screen and (min-width: 1025px) and (max-width: 1366px) {
    .section1_banner_title {
        top: 17rem;
    }

    .section1_banner_title{
        width: 55%;
    }

    .section2{
        margin: 3rem 0;
    }
    .section2_button {
        width: 46%;
    }

    .section2_content{
        width: 40%;
    }

    .section4 {
        height: 16rem;
    }

    .section4_cards {
        top: -32%;
    }

    .section4_cards {
        grid-template-columns: repeat(auto-fill, minmax(191px, 1fr));
    }


}

/* Mid-size Laptop */

@media only screen and (min-width: 1367px) and (max-width: 1600px) {
    .section1_banner_title {
        top: 18rem;
    }

    .section2_button {
        width: 25%;
    }

    .section4 {
        height: 15rem;
    }

    .section4_cards {
        top: -36%;
    }
}

/*Tab*/
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .section1_banner_title {
        top: 12rem;
        padding: 0 78px;
        width: 80%;
        font-size: var(--banner-header-tab);
    }

    .section2 {
        padding: 0 78px;
        margin: 3rem 0;
    }

    .section2_wrapper {
        flex-direction: column-reverse;
    }

    .section2_img {
        width: 100%;
    }

    .section2_content {
        width: 100%;
    }

    .section2_heading {
        width: 100%;
    }

    .our_industries {
        width: 100%;
        white-space: break-spaces;
        font-size: var(--sub-heading-tab);
    }

    .section2_desc {
        margin: 2rem 0;
        font-size: var(--content-tab);
    }

    .section2_button {
        padding: 6px;
        width: 20%;
        font-size: var(--content-tab);
    }

    .section3 {
        justify-content: center;
        padding: 1rem;
        gap: 3rem;
        margin: 10px 44px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(208px, 1fr));
    }
    .section3_wrapper {
        justify-content: center;
    }
    .section3_title {
        font-size: var(--footer-content-mobile);
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .section4_cards_content {
        height: 8rem;
        margin-left: unset;
        margin-top: unset;
    }

    .section4_cards_img {
        width: 2.5rem;
        margin: 1rem;
    }

    .section4 {
        padding: 50px 78px 78px 78px;
        margin: 4rem 0 1rem 0;
        height: auto;
    }

    .section4_heading {
        font-size: var(--sub-heading-tab);
    }

    .section4_content {
        width: 100%;
    }

    .section4_cards {
        position: relative;
        right: 2%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(290px, 4fr));
        z-index: 1;
        grid-gap: 2rem;
    }

    .section4_desc {
        margin-bottom: 2rem;
        font-size: var(--content-tab);
    }

    .section4_cards_title {
        margin: 0rem 1rem;
        font-size: var(--content-tab);
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .section1_banner_title {
        top: 14rem;
        padding: 0 78px;
        font-size: var(--banner-header-tab);
    }

    .our_industries {
        font-size: var(--sub-heading-tab);
    }

    .section2_button {
        width: 30%;
        padding: 5px;
        font-size: var(--content-tab);
    }

    .section2 {
        padding: 0 78px;
        margin: 3rem 0;
    }

    .section2_content {
        width: 100%;
    }

    .section2_img {
        width: 100%;
        height: 100%;
    }

    .section2_desc {
        font-size: var(--content-tab);
    }

    .section3 {
        justify-content: center;
        padding: 2rem;
        gap: 2rem;
        margin: 78px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
    .section3_wrapper {
        justify-content: center;
    }
    .section3_title {
        font-size: var(--footer-content-mobile);
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .section4_heading{
        font-size: var(--sub-heading-tab);
    }

    .section4_desc{
        font-size: var(--content-tab);
    }

    .section4_cards_title{
        font-size: var(--content-tab);
    }

    .section4_cards_content{
        height: 13rem;
    }



    .section4_cards {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        top: -20%;
    }

    .section4 {
        padding: 4% 80px;
        margin: 12rem 0 6rem 0;
        height: 17rem;
    }

    .section4_cards_img {
        width: 3rem;
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    /* Actual */

    .section1_banner_title {
        top: 17rem;
        padding: 0 36px;
        width: 80%;
        font-size: var(--banner-header-mobile);
    }

    .our_industries {
        white-space: break-spaces;
        width: 100%;
        font-size: var(--sub-heading-mobile);
        margin-bottom: 1rem;
    }

    .section2 {
        padding: 0 36px;
        margin: 3rem 0;
    }

    .section2_heading {
        width: 100%;
    }

    .section2_wrapper {
        flex-direction: column-reverse;
    }

    .section2_content {
        width: 100%;
        padding-right: unset;
        display: unset;
        font-size: unset;
    }

    .section2_img {
        width: 100%;
        height: 100%;
    }

    .section2_desc {
        margin: 2rem 0 1rem 0;
        font-size: var(--content-mobile);
    }

    .section2_button {
        width: 45%;
    }

    .section2_button {
        padding: 5px;
        width: 35%;
        font-size: var(--content-mobile);
    }

    .section3 {
        justify-content: center;
        padding: 1rem;
        gap: 1rem;
        margin: 10px 44px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    }
    .section3_wrapper {
        justify-content: center;
    }
    .section3_title {
        font-size: var(--footer-content-mobile);
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .section4_cards_content {
        height: 9rem;
        margin-left: unset;
        margin-top: unset;
    }

    .section4_cards_img {
        width: 2rem;
        margin: 1rem;
    }

    .section4 {
        padding: 36px;
        margin: 4rem 0 4rem 0;
        height: auto;
    }

    .section4_content {
        width: 100%;
    }

    .section4_cards {
        position: relative;
        right: 2%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(122px, 4fr));
        z-index: 1;
        grid-gap: 2rem;
    }

    .section4_desc {
        margin-bottom: 1rem;
        font-size: var(--content-mobile);
    }

    .section4_cards_title {
        margin: 0rem 1rem;
        font-size: var(--content-mobile);
    }

    .section4_heading {
        margin-bottom: 1rem;
        font-size: var(--sub-heading-mobile);
    }
}

/*Media Query for Galaxy fold */
@media only screen and (min-width: 280px) and (max-width: 320px) {

    .section1_banner_title {
        top: 8rem;
        width: 74%;
    }

    .section2_button{
        width: 45%;
    }

    .section2 {
        margin: 2rem 0;
    }

    .section4{
        margin: 4rem 0 0 0;
    }

  

}

