.header {
    /* margin-bottom: 20%;     */
    width: 100%;
    position: relative;
    top: 0;
}
.header_main{
    display: flex;
    font-family: var(--regular-text);
    left: 120px;
    position: fixed;
    right: 120px;
    top: 7%;
    /* width: 81%; */
    align-items: center;
    padding: 1.5rem 3.35%;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: white;
    /* width: calc(100% - 240px); */
    z-index: 11;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #00000029;
    transition: 0.5s;
    justify-content: space-between;
}
.solutions_arrow{
    position: absolute;
    bottom:-28%;
    /* left: 50%; */
    transform: translate(60%);
    z-index: 100;
    
}
.dontdisplayarrow{
    display: none;

}

.header_container{
    align-items: baseline;
    background: transparent;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    width: calc(100% - 8.7rem);
}
.header_ul{
    align-items: baseline;
    display: flex;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    font-family: var(--medium-text);
    font-size: 1rem;
    text-decoration: none;
}
.header_items{
    width: 8rem;
    font-size: var(--content);
    font-family: var( --subheading-font);
    color: black;
    text-decoration: none
}
.link{
    color: black;

}
.header_items:hover{
    color: #FF5B02;
    font-size: var(--content);
    font-family: var( --subheading-font);
}
.header_items:hover .link{
    color: #FF5B02;
}
.link{
    text-decoration: none;
    font-size: var(--content);
    font-family: var(--subheading-font);

    /* color: var(--niurix-homepage-products); */
}
.header_selected{
    width: 8rem;
    color:#FF5B02;
    font-size: var(--content);
    font-family: var( --subheading-font);
    text-decoration: none
}
.contactus_button{
    border: 2px solid #FF5B02;
    padding: 9px;
    width: 15%;
    border-top-left-radius: 10px;
border-bottom-right-radius: 10px; 
padding-left: 23px;
padding-right: 23px;
background-color: #FF5B02;
}
.contactus_button a{
    text-decoration: none;
    font-size: var(--content);
    font-family: var(--subheading-font);
    color: white;

}
.contactus_button:hover{
    background-color: white;
    /* color: black; */
    font-size: var(--content);
    font-family: var(--subheading-font);

}
.contactus_button:hover a{
color: black;

}
.contactus_button:hover .link{
color: white;
font-size: var(--content);
    font-family: var(--subheading-font);
}
.header_dropdown{
    position: absolute;
    top: 7.2rem;
    width: 100%;
    left: 0;
    height: 14.688rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 15px #00000029;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px; 
}
.header_dropdown_content{
    /* width: 100%;  */
    display: flex;
    /* justify-content: space-between; */
    /* justify-content: space-around; */
    height: 100%;
    padding: 0 0.3rem 0 4rem;   
    align-items: center;

}
.dropdown_link{
    font-size: var(--content);
    font-family: var(--subheading-font);
    color: white;
    text-align: center;
}
.header_dropdown_img{
    height: 100%;
    width: 93%;
    overflow: hidden;
    border-radius: 10px 0px;

}
.header_dropdown_img img{
    height: 100%;
    width: 100%;

}

.header_dropdown_flex{
    /* width: 32%; */
    height: 12.5rem;
    width: 5rem;
    position: relative;
    margin-right: 2rem;
}

.header_dropdown_flex:nth-of-type(n) {flex-grow: 1;}

.header_dropdown_heading{
    position: absolute;
    /* margin: auto; */
    top: 0;
    height: 100%;
    width: 93%;
    /* left: 37%; */
    display: flex;
    align-items: center;
    justify-content: center;

}
.header_span{
    display: flex;
    flex-direction: column;
}
.header_products{
    display: flex;
    width: 22.5%;
    height: 85%;
    align-items: center;
    background: #FBFBFB 0% 0% no-repeat padding-box;
    border-radius: 10px 0px;
    margin-right: 2rem;
    justify-content: center;
    gap: 2rem;
}
.header_products_img img{
    display: block;

}
.header_products_img{
    display: inline-block;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s; 
}
.header_dropdown_flex:hover .header_dropdown_img img{
    transform: scale(1.1);
    transition: .5s ease-in-out;
}

.type{
    color: #FF5B02;
    font-size: var(--footer-content);
    font-family: var(--subheading-font);
}
.model_no{
    font-size: var(--content);
    font-family: var(--subheading-font);
    color: var(--nuirix-footer-color);
}
.header_products_img:hover{
    transform: translateY(-10px); /* Move the image up by 10 pixels */

}
.header_logo{
    cursor: pointer;
}
.header_test{
    position: fixed;
    right: 0px;
    left: 0px;
    top: 0px;
    display: flex;
    padding: 1.5rem 120px;
    align-items: center;
    /* width: calc(100% - 240px); */
    align-items: center;
    z-index: 11;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    transition: 0.3s;
    justify-content: space-between;
    box-shadow: 0px 3px 15px #00000029;


    
}
/* .header_test .header_container{
    width: calc(100% - 13.7rem);
} */

.header_test .header_dropdown{
    width: unset;
    right: 120px;
    left: 120px;
}

 /* Mobile */
 .header_initial{
display: none;
}
.tab_n_mobile_header{
    display: none;
}
.header_mob{
    display: none;
}
 @media only screen and (max-width: 767px) {
    .tab_n_mobile_header{
        display: unset;
        background-color: white;
        width: 100%;
        z-index: 10;
        animation: fadeInDown 1s ease-in-out;
    }
    @keyframes fadeInUp {
        from {
            transform: translate3d(0, 0, 0);
        }
    
        to {
            transform: translate3d(0, 40, 0);
            opacity: 1;
        }
    }
    .mob_header_main{
        display: flex;
        justify-content: space-between;
        padding: 1rem;
    }
    .header_main{
        display: none;
        position: unset;
        padding: unset;
        width: unset;
        z-index: unset;
        background:unset;
        transition: unset;    }
    .header_test{
        display: none;
        position: unset;
        padding: unset;
        width: unset;
        z-index: unset;
        background:unset;
        transition: unset;
    }
    .niurix_logo{
        display: flex;
    align-items: center;
    }
    .hamburger_logo{
        display: flex;
        align-items: center;
    }
    .header_card{

display: flex;
    justify-content: space-between;
    padding: 1rem 36px;
    padding-right: 45px;
    text-align: left;
    /* transition: all 5s ease-in-out;
    transition-delay: 10s; */
}
.header_wrapper{
    justify-content: space-between;
    display: flex;
    padding: 1rem 36px;
}
.header_initial{
    display: block;
    background-color: white;
    width: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
}
.header_box_shadow_container{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #00000029; 
    justify-content: space-between;
    display: flex;
    padding: 1rem 36px;
    opacity: 0.75;
}
.plus_logo svg path {
fill: #FF5B02;
;
}
   .header_card_dropdown{
    background-color: #FF8948
    ;
    overflow: auto;
    padding: 0 36px;
   } 
   .hamburger_contents_wrapper{
    height: 100%;
   }
   .header_mob{
    background-color: #fff;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    display: unset;
   }
   .hamburger_contact_btn{
    display: flex;
    justify-content: center;
    width: 100%;
   }
.header_contact_btn{
    background: #ff5b02 0% 0% no-repeat padding-box;
    border: 2px solid #ff5b02;
    border-radius: 10px 0px;
    opacity: 1;
    padding: 0.5rem 1rem 0.5rem 1rem;
    color: #ffffff;
    font-family: var(--subheading-font);
    font-size: var(--mobile-font-h3);
}
.header_card_content{
    text-align: left;
    color: var(--white-color);
    font-family: var(--subheading-font);
    line-height: 3rem;
    font-size: var(--mobile-font-h3);
}
.close{
    margin-bottom: 0.2rem;
    transform: rotate(45deg);
    transition: all .2s ease-in-out;
}
.header_card_wrapper{
    /* animation: header_menu_options__pngz\+ .2s ease-in; */
    display: block;
    overflow: auto;
}
.plus{
    transition: all .2s ease-in-out;  
}
.header_cards{
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.header_logo_wrapper{
    display: flex;
    align-items: center;
}
.header_card_heading{
    font-size: var(--mobile-font-h2);
    font-family: var(--subheading-font);
    color: var(--black-color);
}
.plus_logo{
    display: flex;
    align-items: center;
}
.header_box_shadow_container .header_logo_wrapper svg path{
 fill: black;   
}

}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .tab_n_mobile_header{
        display: unset;
        background-color: white;
        width: 100%;
        z-index: 10;
        animation: fadeInDown 1s ease-in-out;
    }
    @keyframes fadeInUp {
        from {
            transform: translate3d(0, 0, 0);
        }
    
        to {
            transform: translate3d(0, 40, 0);
            opacity: 1;
        }
    }
    .mob_header_main{
        display: flex;
        justify-content: space-between;
        padding: 1rem;
    }
    .header_main{
        display: none;
        position: unset;
        padding: unset;
        width: unset;
        z-index: unset;
        background:unset;
        transition: unset;    }
    .header_test{
        display: none;
        position: unset;
        padding: unset;
        width: unset;
        z-index: unset;
        background:unset;
        transition: unset;
    }
    .niurix_logo{
        display: flex;
    align-items: center;
    }
    .hamburger_logo{
        display: flex;
        align-items: center;
    }
    .header_card{

display: flex;
    justify-content: space-between;
    padding: 1rem 80px;
    padding-right: 90px;
    text-align: left;
    /* transition: all 5s ease-in-out;
    transition-delay: 10s; */
}
.header_wrapper{
    justify-content: space-between;
    display: flex;
    padding: 1rem 80px;
}
.header_initial{
    display: unset;
    background-color: white;
    width: 100%;
    z-index: 10;
    position: fixed;
}
.header_box_shadow_container{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #00000029; 
    justify-content: space-between;
    display: flex;
    padding: 1rem 80px;
    /* opacity: 0.75; */
}
.plus_logo svg path {
fill: #FF5B02;
;
}
   .header_card_dropdown{
    background-color: #FF8948;
    overflow: auto;
    padding: 0 80px;
   } 
   .hamburger_contents_wrapper{
    height: 100%;
   }
   .header_mob{
    background-color: #fff;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    display: unset;
   }
   .hamburger_contact_btn{
    display: flex;
    justify-content: center;
    width: 100%;
   }
.header_contact_btn{
    background: #ff5b02 0% 0% no-repeat padding-box;
    border: 2px solid #ff5b02;
    border-radius: 10px 0px;
    opacity: 1;
    padding: 0.5rem 1rem 0.5rem 1rem;
    color: #ffffff;
    font-family: var(--subheading-font);
    font-size: var(--mobile-font-h3);
}
.header_card_content{
    text-align: left;
    color: var(--white-color);
    font-family: var(--subheading-font);
    line-height: 3rem;
    font-size: var(--tab-font-h3);
}
.close{
    margin-bottom: 0.2rem;
    transform: rotate(45deg);
    transition: all .2s ease-in-out;
}
.header_card_wrapper{
    /* animation: header_menu_options__pngz\+ .2s ease-in; */
    display: block;
    overflow: auto;
}
.plus{
    transition: all .2s ease-in-out;  
}
.header_cards{
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.header_logo_wrapper{
    display: flex;
    align-items: center;
}
.header_card_heading{
    font-size: var(--tab-font-h2);
    font-family: var(--subheading-font);
    color: var(--black-color);
} 
.plus_logo{
    display: flex;
    align-items: center;
}
.header_box_shadow_container .header_logo_wrapper svg path{
    fill: black;   
   }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .header_main{
        left: 78px;
        right: 78px;
    }
    .header_test .header_ul{
        align-items: center;
    }
    .header_test .contactus_button{
        padding-left: 25px;
        padding-right: 25px;
        width: 17%;
    }

    .header_products{
        flex-direction: column;
    }
    .solutions_arrow{
        bottom:-26%;

        
    }

}

/*Semi Desktop */
@media only screen and (min-width: 1025px) and (max-width: 1366px) {
    .header_main{
        width: 75%;
    }

    .header_products{
        flex-direction: column;
    }
    .header_ul{
        align-items: center;
    }
    .header_main{
        width: unset;
    }
    .header_container{
        width: calc(100% - 6.7rem);
    }
    .contactus_button{
        width: 20%;
        text-align: center;
    }
    .header_dropdown_content{
        padding: 0 1% 0 4%;
    }
    .solutions_arrow{
        bottom:-26%;

        
    }


}
