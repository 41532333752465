.niurix_install_video_wrapper{
    /* display: flex; */
    /* width: 60%; */
}
.install_content_wrapper{
    /* width: 40%; */
}
.easy_install_header{
    display: flex;
    margin-left: 0.3rem;
    /* margin-bottom: 1.5rem; */
    justify-content:flex-start ;
}
.orangeDot{
     color: #FF5B02;
     width: 4rem;
     height: 3rem;
   
    display: flex;
    justify-content: center;
    align-items: center;
}
.building_video{
    border-radius: 2rem;
}
.easy_install_header_wrapper{
    display: flex;
}
.easy_install_dot_1{
    border-radius: 50%;
    height: 8px;
    margin-right: 5px;
    width: 8px;
    background-color: #FF5B02;
}
.easy_install_dot{
    border-radius: 50%;
    height: 6px;
    margin-right: 5px;
    width: 6px;
    border: 1px solid #FF5B02;
    /* border: 1px solid orange; */
    /* color: aquamarine; */
    /* background-color: orange; */
}
.whiteDot{
    /* color: lightslategray; */
    
    width: 4rem;
    height: 3rem;
  
    display: flex;
    justify-content: center;
    align-items: center;
}
.orangeButton {
    /* width: 40px;
    height: 40px; */
    width: 35px;
    height: 35px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000001A;
    opacity: 1;
    border-radius: 50%;
    /* border: 2px solid lightgrey; */
    border: none;
    color: white;
    font-size: var(--content-header2);
  
    background-color:  #FF5B02;
}
.whiteButton{
    font-size: var(--content-header2);
    /* width: 40px;
    height: 40px; */
    width: 35px;
    height: 35px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 3px 10px #0000001A; */
    box-shadow: 0px 3px 10px #0000001A;
    opacity: 1;
    border-radius: 50%;
    /* border: 2px solid lightgrey; */
    border: none;
    color: gray;
}
.easy_install_title{
margin-top: 1rem;
font-family: 'nunito-regular';
font-size: var(--content-header1-font);
    font-weight: 300;
}
.easy_install_para{
    margin-top: 1rem;
    font-family: 'nunito-light';
    width: 80%;
    font-size: var(--content-header1-font);
}
.easy_install_wrapper_content{
    font-family: 'nunito-light';
    height: 9rem;
}
.easy_to_install_content_main_wrapper{
    margin-left: 5rem;
}
/** mobile **/
@media only screen and (max-width: 767px) {
   
    .orangeButton {
        width: 28px;
        height: 28px;
    }
    .whiteButton{
        width: 28px;
        height: 28px;
    }
    .easy_install_header{
        align-items: center;
    }
    .easy_install_header_wrapper{
        align-items: center;
    }
    .easy_install_title{
        font-size: var(--content-header2-mobile);
    }
    .easy_install_para{
        width: 100%;
        font-size: var(--content-mobile);
    }
    .easy_to_install_content_main_wrapper{
        margin-left: 1rem;
    }
    .easy_install_header{
        display: flex;
        margin-left: 2.5rem;
        justify-content:flex-start ;
    }
    
}
@media only screen and (min-width: 768px) and (max-width:1024px) and (orientation:portrait){
    .easy_to_install_content_main_wrapper{
        margin-left: 2rem;
    }
    .easy_install_para{
      font-size: var(--content-tab);
    }
    .easy_install_header{
       justify-content: center;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
    .easy_install_para{
       width: 100%;
       font-size: var(--content);
    }
    .easy_install_title{
        font-size:  var(--content);
    }
}