.section1_banner {
    width: 100%;
    height: 100%;
}

.section1_banner img {
    width: 100%;
    height: 100%;
}

.section1_banner_title {
    position: absolute;
    top: 30rem;
    padding: 0 120px;
    color: white;
    font-family: var(--subheading-font);
    font-size: var(--banner-header);
    width: 50%;
    font-weight: unset;
}

.section2 {
    padding: 0 120px;
    margin: 6rem 0;
}

.section2_heading {
    font-size: var(--content-header1);
    font-family: var(--content-header1-font);
    font-weight: unset;
}

.our_solutions {
    font-size: var(--content-header1);
    font-family: var(--content-header1-font);
    width: 65%;
    font-weight: unset;
}
.our_solutions span {
    color: #ff5b02;
}

.section2_wrapper {
    display: flex;
}

.section2_content {
    /* padding: 120px ; */
    width: 40%;
    padding-right: 1rem;
    display: flex;
    align-items: center;
}

.section2_desc {
    margin-top: 1rem;
    font-size: var(--content);
    font-family: var(--content-font);
}
.section2_img img {
    width: 100%;
    height: 100%;
}
.section2_img {
    /* padding: 120px 120px 0 0; */
    width: 60%;
}

.section3_container {
    background-color: #f3f3f3;
    height: auto;
}

.section3_title {
    padding: 80px 0 80px 120px;
    font-size: var(--content-header1);
    font-family: var(--content-header1-font);
}

.cards_container {
    display: flex;
    justify-content: space-between;
    padding: 20px 120px 100px 120px;
}

.section3_cards {
    background-color: white;
    padding: 60px 20px;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 30px;
    height: 17rem;
    box-shadow: 0 0 2px #00000029;
}
.section3_card_title {
    /* padding-bottom: 1rem; */
    text-align: center;
    font-size: var(--content-header2);
    font-family: var(--content-header1-font);
    font-weight: unset;
    width: 100%;
}
.section3_para {
    width: 100%;
    height: 40%;
    margin-top: 1rem;
    font-size: var(--content);
    font-family: var(--content-font);
}
.section3_wrapper {
    width: 32%;
    position: relative;
}

.section3_number {
    position: absolute;
    top: -4rem;
    font-size: 90px;
    color: #ff5b02;
    left: 13rem;
    font-family: var(--niurix-number-font);
}

.section3_img_wrapper {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
}

.section3_img {
    width: 4rem;
}

.section3_img img {
    width: 100%;
}

/* Media Query */

/*Semi-desktop*/
@media only screen and (min-width: 1025px) and (max-width: 1366px) {
    .section1_banner_title {
        top: 22rem;
    }

    .our_solutions {
        width: 80%;
        margin-bottom: 1rem;
    }

    .section2{
        margin: 3rem 0;
    }

    .section3_number {
        left: unset;
        top: -3.5rem;
        display: flex;
        justify-content: center;
        width: 82%;
    }

    .section3_card_title {
        height: 4.5rem;
    }

    .section3_para {
        height: 50%;
    }

    .section3_title {
        padding: 60px 0 50px 120px;
    }

    .section3_cards {
        height: 20rem;
    }
}

/* Mid-size Laptop */

@media only screen and (min-width: 1367px) and (max-width: 1600px) {

    .section1_banner_title {
        top: 28rem;
    }
    .section3_number {
        left: unset;
        top: -3.5rem;
        display: flex;
        justify-content: center;
        width: 82%;
    }

    .section3_title {
        padding: 80px 0 50px 120px;
    }

    .section3_cards{
        height: 15rem;
    }
}

/*Tab*/
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .section1_banner_title {
        top: 12rem;
        padding: 0 78px 0 78px;
        font-size: var( --banner-header-tab);
    }

    .section2 {
        padding: 0 78px;
        margin: 3rem 0;
    }

    .our_solutions {
        width: 100%;
        margin-bottom: unset;
        font-size: var(--sub-heading-tab);
        white-space: break-spaces;
        /* margin-bottom: 1rem; */
    }

    .section2_wrapper {
        display: flex;
        flex-direction: column-reverse;
    }

    .section2_content {
        width: 100%;
    }

    .section2_desc {
        margin-top: unset;
        font-size: var(--content-tab);
    }

    .section2_img {
        width: 100%;
        margin: 1.5rem 0;
    }

    .section3_title {
        padding: 50px 78px;
    }

    .cards_container {
        padding: 20px 78px 100px 78px;
    }

    .section3_cards{
        height: 24rem;
    }

    .section3_img_wrapper {
        margin: 1.5rem 0;
    }
    

    .section3_number {
        left: unset;
        top: -2.8rem;
        display: flex;
        justify-content: center;
        width: 82%;
        font-size: 70px;
    }

    .section3_card_title {
       height: 25%;
       font-size: var(--content-header1-tab);
    }

    .section3_para {
        height: 55%;
        font-size: var(--content-tab);
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .section1_banner_title {
        top: 17rem;
        padding: 0 78px 0 78px;
        font-size: var(--banner-header-tab);
    }

    .section2 {
        padding: 0 78px;
        margin: 3rem 0;
    }

    .our_solutions {
        width: 80%;
        margin-bottom: 1rem;
        font-size: var(--sub-heading-tab);
    }

    .section2_desc {
        font-size: var(--content-tab);
    }

    .section3_title {
        padding: 50px 78px;
    }

    .cards_container {
        padding: 20px 78px 100px 78px;
    }

    .section3_number {
        left: unset;
        top: -2.5rem;
        display: flex;
        justify-content: center;
        width: 82%;
        font-size: 70px;
    }

    .section3_card_title {
       height: 25%;
       font-size: var(--content-header1-tab);
    }

    .section3_para {
        height: 55%;
        font-size: var(--content-tab);
    }

    .section3_img_wrapper {
        margin: unset;
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {


    .section1_banner_title {
        top: 22rem;
        padding: 0 36px;
        width: 75%;
        font-size: var( --banner-header-mobile);
    }

    .our_solutions {
        white-space: break-spaces;
    }


    .section2 {
        padding: 0 36px;
        margin: 3rem 0 4rem 0;
    }


    .section2_wrapper {
        flex-direction: column-reverse;
    }

    .section2_content {
        width: 100%;
        padding-right: unset;
        display: unset;
        font-size: unset;
    } 

    

    .section2_img {
        width: 100%;
        height: 100%;
    }

    .section2_desc {
        font-size: var(--content-mobile);
    }

    .cards_container {
        display: unset;
        padding: unset;
    }


    .section3_wrapper {
        width: 90%;
        padding: 1rem 1rem 3rem 1rem;
        
    }

    .section3_title {
        padding:36px;
    }

    .section3_number {
        left: unset;
        top: -1rem;
        display: flex;
        justify-content: center;
        width: 82%;
        font-size: 60px;
    }

    .our_solutions {
        width: 100%;
        margin-bottom: 1rem;
        font-size: var(--sub-heading-mobile);
    }

    .section3_card_title {
        font-size: var( --content-header1-mobile);
    }

    .section3_para {
        font-size: var(--content-mobile);
    }

    .section3_cards {
        height: 12rem;
    }
}

/*Media Query for Galaxy fold */
@media only screen and (min-width: 280px) and (max-width: 320px) {

    .section1_banner_title {
        top: 18rem;
    }

    .section2 {
        margin: 2rem 0;
    }


    .section3_number{
        width: 78%;
    }

}
